@import "../../styles/everestStyleVariables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 5;
}

.background {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background-color: $gray;
  opacity: 0.2;
}

:global(.ms-Spinner-label) {
  background-color: $white;
  font-weight: 600;
}

.wrapper__full_container {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
}

