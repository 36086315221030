@import "../../styles/everestStyleVariables";
@import "../../styles/animations";

.wrapper {
  height: 100%;
  position: relative;

  .content {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);

    .title_container {
      display: flex;
      justify-content: center;

      .icon {
        width: 68px;
        height: 60px;
      }

      .title {
        margin-left: 20px;
        font-size: 42px;
      }
    }

    .message {
      margin-top: 40px;
      font-size: 18px;
      white-space: pre;
      text-align: center;
      color: $gray;
    }
  }

  .code {
    position: absolute;
    bottom: 0;
    left: 0;

    font-size: 16px;
    color: $gray;
  }
}
