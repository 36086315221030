@import '../../styles/everestStyleVariables';

.popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: $black;
  opacity: 0.5;
  z-index: 1;
}

.wrapper {
  position: absolute;
  min-width: 320px;
  font-size: 14px;
  z-index: 1;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: $white;

  &.hidden {
    opacity: 0;
  }
}

.header {
  position: relative;

  display: flex;
  align-items: center;

  height: 40px;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: $allvue;

  & > .title {
    color: $white;
    font-family: 'Segoe UI', serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;

    width: calc(100% - 40px);
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .cross {
    position: absolute;
    top: 2px;
    right: 0;

    cursor: pointer;
    padding: 10px;

    & g {
      fill: $white;
    }
  }
}

.close {
  cursor: pointer;
}

.content {
  padding: 10px 10px 20px 10px;
}

.buttons {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $gray-lighter;
  border-top: 1px solid $gray-light;
  padding: 0 12px;


  & > button + button {
    margin-left: 10px;
  }
}
