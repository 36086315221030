$black: black;
$white: white;
$blue: #1E5E9F;
$green: #288156;
$green1: #FAFFFC;
$green2: #F3FCF7;
$red: #C10000;
$light-red: #fde7e9;
$yellow: #F1CA2C;
$yellow1: #FFFAE8;



$allvue-dark: var(--dataroom-primary-color-dark, var(--fundraising-primary-color-dark, var(--primary-color-dark, #4D3FEE)));
$allvue: var(--dataroom-primary-color, var(--fundraising-primary-color, var(--primary-color, #8179FF)));
$allvue-light: var(--dataroom-primary-color-light, var(--fundraising-primary-color-light, var(--primary-color-light, #D7D5F6)));
$allvue-lighter: var(--dataroom-primary-color-lighter, var(--fundraising-primary-color-lighter, var(--primary-color-lighter, #F8F8FF)));

$gray-dark: #333; // main text color
$gray: #757575; // light text color & dark border color
$gray2: #B5B5B5; // icons color
$gray-light: #DEDEDE; // border color
$gray-lighter: #F9F9F9; // background color


$font-xl: 24px;
$font-l: 20px;
$font-m: 14px;
