@keyframes shake {
  0% { transform: translate(2px, 1px) rotate(0deg); }
  2% { transform: translate(-1px, -2px) rotate(-1deg); }
  4% { transform: translate(-3px, 0px) rotate(1deg); }
  6% { transform: translate(0px, 2px) rotate(0deg); }
  8% { transform: translate(1px, -1px) rotate(1deg); }
  10% { transform: translate(-1px, 2px) rotate(-1deg); }
  12% { transform: translate(-3px, 1px) rotate(0deg); }
  14% { transform: translate(2px, 1px) rotate(-1deg); }
  16% { transform: translate(-1px, -1px) rotate(1deg); }
  18% { transform: translate(2px, 2px) rotate(0deg); }
  20% { transform: translate(1px, -2px) rotate(-1deg); }
  21%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
}