@import "../../styles/everestStyleVariables";

.wrapper {
  position: relative;
  left: -4px;

  width: 20px;
  height: 20px;

  cursor: pointer;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: 4px;
  box-sizing: border-box;
}
