@import "../../styles/everestStyleVariables";

.wrap {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.item + .item{
  margin-top: 10px;
}

.container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 0.9;
  background: $yellow1;
  border: 1px solid $yellow;
  border-radius: 3px;
  &.error {
    background: $light-red;
    border: 1px solid $red;
  }
}

.dismiss_all {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-light;
  border: 1px solid $gray2;
  border-radius: 3px;
  cursor: pointer;
}

.inner {
  padding: 10px 15px;
  display: flex;
  word-break: break-word;
  white-space: pre-line;
}

.controls {
  min-height: 70px;
}

.close {
  cursor: pointer;
  padding: 10px 10px 5px 0;

  g {
    fill: $gray-dark;
  }
}

.copy {
  position: absolute;
  bottom: 6px;

  cursor: pointer;
  padding: 5px 10px 5px 0;
  width: 22px;
  box-sizing: border-box;

  svg {
    fill: $gray-dark;
  }
}
